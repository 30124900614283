<template>
  <div class="events">
    <h1>Events For Good</h1>
    <EventCard v-for="event in events" :key="event.id" :event="event" />

    <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
      <router-link
        :to="{ name: 'EventList', query: { page: page -1 } }"
        rel="prev"
        class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        v-if="page != 1" >Prev Page</router-link>

      <router-link
        :to="{ name: 'EventList', query: { page: page +1 } }"
        rel="next"
        class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        v-if="hasNextPage" >Next Page</router-link>

    </nav>
  </div>
</template>

<script>
import EventCard from '@/components/EventCard.vue'
import { watchEffect } from 'vue'

export default {
  name: 'EventList',
  props: ['page'],
  components: {
    EventCard,
  },
  created() {
    watchEffect(() => {
      this.$store.dispatch('fetchEvents', { perPage: 2, page: this.page}).catch((error) => {
        this.$router.push({
          name: 'ErrorDisplay',
          params: { error: error },
        })
      })
    })
  },
  computed: {
    events() {
      return this.$store.state.events
    },
    hasNextPage() {
      var totalPages = Math.ceil(this.$store.state.totalEvents / 2)
      return this.page < totalPages
    }
  },
}
</script>

<style scoped>
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
