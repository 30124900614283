<template>
  <h4>Ops! There was an error:</h4>
  <p>{{ error }}</p>
</template>

<script>
export default {
  props: ['error'],
}
</script>

<style></style>
